import PropTypes from 'prop-types';
import React from 'react';

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="services"
          className={`${this.props.article === 'services' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Services</h2>
          <table>
            <tr>
              <td width="700px">
                <i class="fa fa-line-chart" aria-hidden="true"></i>
                <h4> Security Monitoring </h4>
                Years of experience within MSSP and enterprise security means we are 
                able to work with organisations to review their current security 
                monitoring approach, create new logging solutions or help you to 
                enhance existing setup. Experienced in a range of commercial and open 
                source options we can help you in a number of ways from improving your 
                posture through better data capture to engineering automation solutions 
                to reduce analyst fatigue.
              </td>
              <td width="700px">
                <i class="fa fa-bug" aria-hidden="true"></i>
                <h4> Vulnerability Management </h4>
                Vulnerability management is key to defending your organisation from an 
                ever growing number of exploits and attackers. We can help you to 
                strengthen an existing programme or help you to build your own from 
                scratch. With experience gained by supporting FTSE100 and Fortune 500 
                organisations we can work with you on everything from programme design 
                to asset classification, scanning techniques to remediation approaches.
              </td>
              <td width="700px">
                <i class="fa fa-handshake-o" aria-hidden="true"></i>
                <h4> Managed Security Providers </h4>
                Who better to review or recommend your managed security provider than 
                someone who has built and run one themself? Selecting an MSSP that is 
                right for you is critical in ensuring the integration with your business 
                that will drive real results and identify the incidents that you need to 
                know about. Understanding how MSSPs work on the inside and asking the 
                probing questions is key to finding the right fit with your organisation. 
                If you already retain an MSSP you need to be certain they’re working for 
                you, a detailed service review will help to determine if you’re getting 
                the most from your security partner.
              </td>
            </tr>
            <tr>
              <td>
                <i class="fa fa-wrench" aria-hidden="true"></i>
                <h4> Security Tools </h4>
                Technology in the security space is getting more and more complex and 
                it’s hard to know which solution best fits your needs. Having implemented 
                a wide variety of technologies from EDR to SIEM, DLP to CASB, we are 
                ideally placed to support you in identifying your requirements and advising 
                on a solution that will fit your organisation best. We provide an end-to-end 
                service from requirement capture to delivery consultancy that will help you 
                implement technologies to defend your organisation.
              </td>
              <td>
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                <h4> Security Architecture </h4>
                Security roadmaps are often composed of complex, multi-disciplinary deliveries 
                across a range of technologies and in a variety of your organisations 
                departments. The ability to break down these roadmaps into manageable deliveries 
                is key to keeping your progress high and improving your security posture quickly. 
                We have helped to build programmes and projects of work across industries as 
                diverse as Formula 1, gaming, manufacturing and banking with the focus on 
                delivering in a way that meets your needs and available resources.
              </td>
              <td>
                <i class="fa fa-users" aria-hidden="true"></i>
                <h4> People and Processes </h4>
                The team that you employ to build, monitor and maintain your security solution 
                are absolutely key to the defence of your business. Their knowledge of security 
                and the way they utilise that knowledge are critical. From a people perspective 
                it's key to ensure they are provided training, career paths, mentoring and the
                ability to grow with your organisation. At the same time they need efficient 
                processes that save them time while allowing them to defend your business. We can
                undertake a review of your current capability in both areas and help build roadmaps 
                for developing maturity.
              </td>
            </tr>
          </table>
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          {/* <span className="image main">
            <img src={pic03} alt="" />
          </span> */}
          <h2 className="major">James Phelpstead</h2>
          <h4> Principal Consultant and Founder </h4>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/in/james-phelpstead-0760142/"
                target="_blank"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
          </ul>
          <h3> About </h3>
          <p>
            James mixes the capabilities of a senior leader with the ability to architect 
            and engineer a wide range of solutions. This cross section of skills provides 
            expertise at all stages of the project lifecycle from problem analysis to 
            technical delivery and all phases in between.
          </p>
          <p>
            Throughout his career he has taken responsibility for security engineering and 
            operations teams, worked in project and product management, managed architecture 
            teams and been responsible for service design and delivery. This unique mix of 
            skills means that James is able to understand your business, understand a broad 
            set of requirements and help you build solutions to your security needs.
          </p>
          <h3> Background </h3>
          <p>
            James' security career started with one of the UKs most successful SaaS security 
            startups where he worked in both technical and leadership roles developing and 
            delivering solutions for a range of businesses from SMB to Enterprise. 
          </p>
          <p>  
            Moving on to a managed security provider he spent time project managing the 
            delivery of services to a range of customers from government to Formula 1. During 
            this time he built a delivery process, spent time managing the SOC and security 
            engineering teams and architected a new platform to deliver multi-tenancy. 
          </p>
          <p>
            More latterly James was responsible for security engineering and operations for a 
            household name in the manufacturing industry where he helped develop and mature 
            their security capability from both an analytics and capability perspective.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form action="https://formspree.io/f/mvodbndw" method="POST">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <button type="submit">Send Message</button>
          </form>
          <ul className="icons">
            <li>
              <a
                href="https://www.linkedin.com/company/diagonal-lines"
                target="_blank"
                className="icon fa-linkedin"
              >
                <span className="label">LinkedIn</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}  

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main