import PropTypes from 'prop-types'
import React from 'react'
import logo_small from '../images/dl_small_light.png'
import img from 'gatsby-image'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <div className="logo-image">
        <img src={logo_small} width="65%" alt="Diagonal Lines"/>
      </div>
    </div>
    <div className="content">
      <div className="inner">
        <h1>Diagonal Lines</h1>
        <p>
          A cyber security consultancy built on experience <br /> working in security vendors, managed security providers <br /> and enterprise security.
        </p>
      </div>
    </div>
    <nav>
      <ul>
      <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('services')
            }}
          >
            Services
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
